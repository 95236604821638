import { type AxiosRequestConfig } from "axios";
import { useMsal } from "@azure/msal-react";

import {
  type Dataset,
  type MediaType,
  DatasetApi,
  type DatasetCreate,
  DatasetState,
} from "../openapi/api";
import { Configuration } from "../openapi/configuration";

export const useApi = () => {
  const { instance } = useMsal();

  const config = new Configuration({
    basePath: `${window.REACT_APP_MEDIA_DATA_URL}`,
  });

  const datasetApi = new DatasetApi(config);

  const getToken = async () => {
    let token;
    let tokenError;
    const tenantAppId = `${window.REACT_APP_TENANT_APP_ID}`;

    try {
      const response = await instance.acquireTokenSilent({
        scopes: [`api://${tenantAppId}/.default`],
      });
      token = response.accessToken;
    } catch (error) {
      tokenError = error;
    }

    return token;
  };

  const getHealth = () => {
    // TODO
    return true;
  };

  const getOptions = async (): Promise<AxiosRequestConfig> => {
    const token = await getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  const createDataset = async (
    name: string,
    reference: string,
    comment: string,
  ) => {
    const dataset: DatasetCreate = {
      name: name,
      reference: reference,
      comment: comment,
    };

    try {
      const additionalOptions = await getOptions();
      const response = await datasetApi.createDataset(
        dataset,
        additionalOptions,
      );
      return response;
    } catch (error) {
      console.error("Error while performing request: " + error);
    }
  };

  const createFile = async (
    datasetId: string,
    name: string,
    type: MediaType,
    file: File,
  ) => {
    const additionalOptions = await getOptions();
    try {
      const response = await datasetApi.createFile(
        datasetId,
        name,
        type,
        file,
        undefined,
        additionalOptions,
      );
      return response;
    } catch (error) {
      console.error("Error while performing request: " + error);
    }
  };

  const getDatasetById = async (datasetId: string) => {
    const additionalOptions = await getOptions();
    try {
      const response = await datasetApi.findDatasetById(
        datasetId,
        additionalOptions,
      );
      return response;
    } catch (error) {
      console.error("Error while performing request: " + error);
    }
  };

  const findFilesByDatasetId = async (
    datasetId: string,
    type: MediaType | undefined = undefined,
  ) => {
    const additionalOptions = await getOptions();
    let hasNextPage = false;
    let offset = 0;
    const allFiles: File[] = [];

    try {
      do {
        const response = await datasetApi.findFilesByDatasetId(
          datasetId,
          offset,
          undefined,
          undefined,
          type,
          additionalOptions,
        );
        response.data.items.forEach((file) => allFiles.push(file));
        hasNextPage = !!response.data.next;
        offset += response.data.items.length;
      } while (hasNextPage);
    } catch (error) {
      console.error("Error while performing request: " + error);
    }
    return allFiles;
  };

  const findDatasets = async (
    sort?: string,
    state?: DatasetState,
    createdAtBefore?: string,
    createdAtAfter?: string,
  ) => {
    const additionalOptions = await getOptions();
    let hasNextPage = false;
    let offset = 0;
    const allDatasets: Dataset[] = [];

    try {
      do {
        const response = await datasetApi.findDatasets(
          offset,
          undefined,
          sort,
          undefined,
          state,
          undefined,
          undefined,
          createdAtBefore + "T23:59:59",
          createdAtAfter + "T00:00:00",
          additionalOptions,
        );
        response.data.items.forEach((dataset) => allDatasets.push(dataset));
        hasNextPage = !!response.data.next;
        offset += response.data.items.length;
      } while (hasNextPage);
    } catch (error) {
      console.error("Error while performing request: " + error);
    }
    return allDatasets;
  };

  const finishDataset = async (datasetId: string) => {
    const additionalOptions = await getOptions();

    try {
      const response = await datasetApi.finishDataset(
        datasetId,
        additionalOptions,
      );
      return response;
    } catch (error) {
      console.error("Error while performing request: " + error);
    }
  };

  return {
    getHealth,
    createDataset,
    createFile,
    findDatasets,
    getDatasetById,
    findFilesByDatasetId,
    finishDataset,
  };
};
