import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  TextField,
  InputAdornment,
  LinearProgress,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloudUploadOutlined from "@mui/icons-material/CloudUploadOutlined";
import { v4 as uuidv4 } from "uuid";
import DragAndDrop from "../components/uploader//DragAndDrop";
import { useApi } from "../hooks/useApi";
import IconTooltip from "../components/uploader/IconTooltip";
import InfoSnackbar from "../components/uploader/InfoSnackbar";

import { FormField, UploadedFile, UploadParams } from "../types";
import {
  Folder,
  Notes,
  AddLink,
  UploadFile,
  ModeEdit,
} from "@mui/icons-material";

import { CDN_IMAGES } from "../util/images";
import UploadSuccessDialog from "../components/uploader/UploadSuccessDialog";
import DatasetNameDialog from "../components/uploader/DatasetNameDialog";
import { DatasetState, MediaType } from "../openapi/api";

interface UploadViewProps {
  isOnline: boolean;
  getKID: () => string;
}

export const UploadView = (props: UploadViewProps) => {
  const { createDataset, createFile, getDatasetById, finishDataset } = useApi();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [showDatesetNameDialog, setShowDatesetNameDialog] =
    useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [progressText, setProgressText] = useState<string>("Creating dataset");

  const [formData, setFormData] = useState<Record<FormField, string>>({
    file: "",
    datasetName: "",
    comment: "",
    reference: "",
  });

  const [formErrors, setFormErrors] = useState<Record<FormField, string>>({
    file: "",
    datasetName: "",
    comment: "",
    reference: "",
  });

  const handleCommentChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData((formData) => ({
      ...formData,
      comment: event.target.value,
    }));
  };

  const handleReferenceChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData((formData) => ({
      ...formData,
      reference: event.target.value,
    }));
  };

  const handleDeleteFile = (fileId: string | undefined) => {
    if (fileId) {
      setUploadedFiles((files) => files.filter((file) => file.id !== fileId));

      if (uploadedFiles.length == 0) {
        setFormData({ ...formData, datasetName: "" });
      }
    } else {
      setUploadedFiles([]);
      setFormData({ ...formData, datasetName: "" });
    }
  };

  const validateForm = () => {
    const newFormErrors: Record<FormField, string> = {
      file: "",
      datasetName: "",
      reference: "",
      comment: "",
    };

    if (formData.comment && formData.comment.length > 100) {
      newFormErrors.reference =
        "Dataset comment cannot be longer than 100 characters";
    }

    if (formData.reference && formData.reference.length > 100) {
      newFormErrors.reference =
        "Dataset reference cannot be longer than 20 characters";
    }

    if (!formData.datasetName) {
      newFormErrors.datasetName = "Dataset name is required";
    }

    if (
      formData.datasetName &&
      !/^[a-zA-Z0-9_ .-]{0,512}$/.test(formData.datasetName)
    ) {
      newFormErrors.datasetName = "No special or more than 50 characters";
    }

    if (uploadedFiles.length == 0) {
      newFormErrors.file = "You need to select a file";
    }

    setFormErrors(newFormErrors);

    // return true if one value in newFormErrors is !== ""
    return !(Object.keys(newFormErrors) as Array<FormField>).some(
      (key) => newFormErrors[key] !== "",
    );
  };

  const uploadCleanup = () => {
    setUploadSuccess(false);
    setUploadedFiles([]);
    setFormData({
      file: "",
      datasetName: "",
      comment: "",
      reference: "",
    });
    setProgress(0);
    setProgressText("Creating dataset");
  };

  const uploadFiles = async () => {
    setIsUploading(true);

    const response = await createDataset(
      formData.datasetName,
      formData.reference,
      formData.comment,
    );

    if (response) {
      let counter = 0;
      const datasetId = response.data["id"];

      for (const uploadedFile of uploadedFiles) {
        setProgressText("Uploading file " + uploadedFile.file.name);
        await createFile(
          datasetId,
          uploadedFile.file.name,
          MediaType.DroneImage,
          uploadedFile.file,
        );
        counter += 1;
        const percentage = (100 / uploadedFiles.length) * counter;
        setProgress(percentage);
      }

      setIsUploading(false);
      setProgressText("Done Uploading");
      const dataset = await getDatasetById(datasetId);
      if (dataset && dataset.data.state == DatasetState.New) {
        await finishDataset(datasetId);
        setUploadSuccess(true);
      } else {
        console.error(
          "Could not finish dataset: " + JSON.stringify(dataset, null, 2),
        );
      }
    } else {
      console.error("Could not create dataset");
    }
  };

  useEffect(() => {
    if (props.isOnline && isUploading) {
      uploadFiles();
    }
  }, [props.isOnline]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm()) {
      // scroll to bottom of page, so user sees the progressbar
      window.scrollTo(0, document.body.scrollHeight);
      uploadFiles();
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    setFormErrors((errors) => ({
      ...errors,
      file: "",
    }));
    event.stopPropagation();
    event.preventDefault();

    const entry = event.dataTransfer.items[0].webkitGetAsEntry();

    if (!entry) return;

    if (entry.isFile) {
      (entry as FileSystemFileEntry).file((file) => {
        if (file.type != "image/jpeg") {
          setFormErrors((errors) => ({
            ...errors,
            file: "Only jpeg files and folder with only images are allowed.",
          }));
          return;
        }
        const newUploadedFile: UploadedFile = {
          id: uuidv4(),
          file: file,
        };
        setUploadedFiles([...uploadedFiles, newUploadedFile]);
      });
    } else if (entry.isDirectory) {
      setFormData({ ...formData, datasetName: entry.name });

      const tmpFiles: UploadedFile[] = [];
      const reader = (entry as FileSystemDirectoryEntry).createReader();

      reader.readEntries(function (entries) {
        const totalFiles = entries.length;
        let counter = 0;

        entries.forEach((dir) => {
          (dir as FileSystemFileEntry).file((file) => {
            counter++;

            if (file.type != "image/jpeg") {
              setFormErrors((errors) => ({
                ...errors,
                file: "Only jpeg files and folder with only images are allowed.",
              }));
              return;
            }

            const newUploadedFile: UploadedFile = {
              id: uuidv4(),
              file: file,
            };

            tmpFiles.push(newUploadedFile);

            if (totalFiles == counter) {
              setUploadedFiles([...uploadedFiles, ...tmpFiles]);
            }
          });
        });
      });
    }
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.target.files == null) return;

    setFormErrors((errors) => ({
      ...errors,
      file: "",
    }));

    if (event.target.files.length > 1) {
      setFormData({
        ...formData,
        datasetName: event.target.files[0].webkitRelativePath.split("/")[0],
      });
      const tmpFiles: UploadedFile[] = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const newUploadedFile: UploadedFile = {
          id: uuidv4(),
          file: event.target.files[i],
        };

        tmpFiles.push(newUploadedFile);
      }
      setUploadedFiles([...uploadedFiles, ...tmpFiles]);
    } else {
      const newUploadedFile: UploadedFile = {
        id: uuidv4(),
        file: event.target.files[0],
      };
      setUploadedFiles([...uploadedFiles, newUploadedFile]);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        paddingY="3em"
        gap={4}
      >
        <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
          <Box width="100px">
            <img
              src={CDN_IMAGES.logos("media_data_logos/md_uploader.svg")}
              height="100%"
              width="auto"
              alt="mdp-viewer"
            />
          </Box>
          <Typography variant="h4" fontWeight="bold">
            Welcome to Media Data Uploader
          </Typography>
          {/* <Typography variant="subtitle1">
            Bring your Media Data to iPEN
          </Typography> */}
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{ width: "500px" }}
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={2}
          >
            <Box display="flex" alignItems="start" gap={1} width={1}>
              <TextField
                id="dataset-name"
                fullWidth
                placeholder="Dataset name"
                disabled
                aria-label="Dataset Textfield"
                value={formData.datasetName}
                helperText={formErrors.datasetName}
                error={formErrors.datasetName != ""}
                inputProps={{ "data-testid": "dataset-textfield" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Folder />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="dataset-name-edit-button"
                        onClick={() => {
                          setShowDatesetNameDialog(true);
                        }}
                      >
                        <ModeEdit />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconTooltip text="Enter your Dataset Name to identify the uploaded file" />
            </Box>

            <Box
              sx={{ width: "500px" }}
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap={5}
            >
              <Box display="flex" alignItems="start" gap={1} width={1}>
                <TextField
                  id="dataset-comment"
                  fullWidth
                  placeholder="Dataset comment"
                  aria-label="Comment Textfield"
                  value={formData.comment}
                  helperText={formErrors.comment}
                  error={formErrors.comment != ""}
                  onChange={handleCommentChange}
                  inputProps={{ "data-testid": "comment-textfield" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Notes />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconTooltip text="Enter a comment for the Dataset for additional information." />
              </Box>
            </Box>

            <Box
              sx={{ width: "500px" }}
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap={5}
            >
              <Box display="flex" alignItems="start" gap={1} width={1}>
                <TextField
                  id="dataset-reference"
                  fullWidth
                  placeholder="Dataset reference"
                  aria-label="Reference Textfield"
                  value={formData.reference}
                  helperText={formErrors.reference}
                  error={formErrors.reference != ""}
                  onChange={handleReferenceChange}
                  inputProps={{ "data-testid": "reference-textfield" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddLink />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconTooltip text="Enter a reference for the Dataset." />
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              width={1}
              flexDirection="column"
            >
              <DragAndDrop
                dragAndDropAriaLabel="Drag and Drop field to upload Media Data"
                uploadedFiles={uploadedFiles}
                zipStats={null}
                handleFileSelect={handleFileSelect}
                handleDrop={handleDrop}
                handleDeleteFile={handleDeleteFile}
                helperText={formErrors.file}
                hasError={formErrors.file != ""}
                isUploading={isUploading}
                datasetName={formData.datasetName}
              />
            </Box>

            {isUploading && uploadedFiles.length > 0 && (
              <Box
                display="flex"
                width={1}
                alignItems="center"
                border={1}
                py={2}
                borderRadius={1}
                borderColor={isUploading ? "secondary.main" : "success.main"}
              >
                <UploadFile
                  color={isUploading ? "secondary" : "success"}
                  sx={{ p: 2 }}
                />
                <Box display="flex" flexDirection="column" width={1} gap="3px">
                  <Typography variant="body1">
                    {formData.datasetName}
                  </Typography>
                  <Typography variant="body2" color="gray" component="div">
                    {/* {humanFileSize(uploadingFile?.size || 0)} •{" "} */}
                    {isUploading ? (
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        pl={1}
                        gap={1}
                      >
                        <CircularProgress
                          size={12}
                          color="secondary"
                          data-testid="upload-loading"
                        />{" "}
                        {progressText}
                      </Box>
                    ) : (
                      "Complete"
                    )}
                  </Typography>
                  <LinearProgress
                    sx={{ width: "50%", borderRadius: 2, opacity: 0.7 }}
                    variant="determinate"
                    color={isUploading ? "secondary" : "success"}
                    value={progress}
                    aria-label="Progressbar to display the upload progress"
                  />
                </Box>
              </Box>
            )}

            <Button
              type="submit"
              data-testid="submit"
              variant="contained"
              color="secondary"
              size="large"
              disabled={isUploading}
              startIcon={<CloudUploadOutlined />}
            >
              Upload
            </Button>
          </Box>
        </form>
      </Grid>

      <InfoSnackbar
        open={!props.isOnline}
        message={
          !props.isOnline && isUploading
            ? `Internet connection lost - Failed to upload chunk X`
            : "Internet connection lost"
        }
        severity="warning"
        hideDuration={0}
      />

      <UploadSuccessDialog
        datasetName={formData.datasetName}
        uploadSuccess={uploadSuccess}
        setUploadSuccess={uploadCleanup}
      />

      <DatasetNameDialog
        showDatasetNameDialog={showDatesetNameDialog}
        formData={formData}
        setShowDatasetNameDialog={setShowDatesetNameDialog}
        setFormData={setFormData}
      />
    </>
  );
};
