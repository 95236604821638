/* tslint:disable */
/* eslint-disable */
/**
 * Media Data Platform
 * This is the iPEN Media Data Platform API.
 *
 * Contact: ipen-squad-mediadataplatform@eon-intern.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Defines Azure application, e.g. [RUN-IPEN_DEMO1_APP-APL210663](https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/20d9a8f1-8697-4b6a-80ec-925099d95187).
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * Azure display name of application.
     * @type {string}
     * @memberof Application
     */
    'display_name': string;
    /**
     * Azure objectId of application.
     * @type {string}
     * @memberof Application
     */
    'object_id': string;
    /**
     * Azure applicationId of application.
     * @type {string}
     * @memberof Application
     */
    'application_id': string;
}
/**
 * Dataset for list view.
 * @export
 * @interface Dataset
 */
export interface Dataset {
    /**
     * Primary key for objects managed by api.
     * @type {string}
     * @memberof Dataset
     */
    'id': string;
    /**
     * Primary key for objects managed by api.
     * @type {string}
     * @memberof Dataset
     */
    'tenant_id': string;
    /**
     * Timestamp where object was created by user.
     * @type {string}
     * @memberof Dataset
     */
    'created_at': string;
    /**
     * ObjectID from jwt token (claim __oid__).
     * @type {string}
     * @memberof Dataset
     */
    'created_by': string;
    /**
     * Timestamp where object was modified by user.
     * @type {string}
     * @memberof Dataset
     */
    'modified_at': string;
    /**
     * ObjectID from jwt token (claim __oid__).
     * @type {string}
     * @memberof Dataset
     */
    'modified_by': string;
    /**
     * Primary key for objects managed by api.
     * @type {string}
     * @memberof Dataset
     */
    'solution_id'?: string;
    /**
     * User selected name of a dataset.
     * @type {string}
     * @memberof Dataset
     */
    'name': string;
    /**
     * User selected comment.
     * @type {string}
     * @memberof Dataset
     */
    'comment'?: string;
    /**
     * External id for reference.
     * @type {string}
     * @memberof Dataset
     */
    'reference'?: string;
    /**
     * Count of files within a dataset.
     * @type {number}
     * @memberof Dataset
     */
    'files_count': number;
    /**
     * Size of all files in dataset in bytes.
     * @type {number}
     * @memberof Dataset
     */
    'files_size': number;
    /**
     * Oldest creation date of the files, e.g. image taken timestamp.
     * @type {string}
     * @memberof Dataset
     */
    'file_at'?: string;
    /**
     * 
     * @type {DatasetState}
     * @memberof Dataset
     */
    'state': DatasetState;
    /**
     * Timestamp where object was modified by processing.
     * @type {string}
     * @memberof Dataset
     */
    'state_at': string;
    /**
     * Bound Box of the for all the files in the dataset.
     * @type {string}
     * @memberof Dataset
     */
    'bounding_box_geometry'?: string;
}


/**
 * Dataset to create.
 * @export
 * @interface DatasetCreate
 */
export interface DatasetCreate {
    /**
     * Primary key for objects managed by api.
     * @type {string}
     * @memberof DatasetCreate
     */
    'solution_id'?: string;
    /**
     * User selected name of a dataset.
     * @type {string}
     * @memberof DatasetCreate
     */
    'name': string;
    /**
     * User selected comment.
     * @type {string}
     * @memberof DatasetCreate
     */
    'comment'?: string;
    /**
     * External id for reference.
     * @type {string}
     * @memberof DatasetCreate
     */
    'reference'?: string;
}
/**
 * 
 * @export
 * @interface DatasetPage
 */
export interface DatasetPage {
    /**
     * Pagination link pointing to the current page.
     * @type {string}
     * @memberof DatasetPage
     */
    'self': string;
    /**
     * Pagination link pointing to the first page.
     * @type {string}
     * @memberof DatasetPage
     */
    'first': string;
    /**
     * Pagination link pointing to the previous page.
     * @type {string}
     * @memberof DatasetPage
     */
    'prev'?: string;
    /**
     * Pagination link pointing to the next page.
     * @type {string}
     * @memberof DatasetPage
     */
    'next'?: string;
    /**
     * Pagination link pointing to the last page.
     * @type {string}
     * @memberof DatasetPage
     */
    'last': string;
    /**
     * Numeric offset of the first element provided on a page representing a collection request.
     * @type {number}
     * @memberof DatasetPage
     */
    'offset': number;
    /**
     * Cient suggested limit to restrict the number of entries on a page. Can be truncated by server side if limit exceeds server limit.
     * @type {number}
     * @memberof DatasetPage
     */
    'limit': number;
    /**
     * 
     * @type {Array<Dataset>}
     * @memberof DatasetPage
     */
    'items': Array<Dataset>;
    /**
     * Total count of items.
     * @type {number}
     * @memberof DatasetPage
     */
    'count': number;
}
/**
 * Status of a dataset: - NEW: dataset was created but not finished by uploader - PROCESSING: dataset is processed - FINISHING: dataset is being finished - FINISHED: dataset was finished - FAILED: dataset processing failed .
 * @export
 * @enum {string}
 */

export const DatasetState = {
    New: 'NEW',
    Processing: 'PROCESSING',
    Finishing: 'FINISHING',
    Finished: 'FINISHED',
    Failed: 'FAILED'
} as const;

export type DatasetState = typeof DatasetState[keyof typeof DatasetState];


/**
 * 
 * @export
 * @interface DatasetUpdate
 */
export interface DatasetUpdate {
    /**
     * User selected name of a dataset.
     * @type {string}
     * @memberof DatasetUpdate
     */
    'name'?: string;
    /**
     * User selected comment.
     * @type {string}
     * @memberof DatasetUpdate
     */
    'comment'?: string;
    /**
     * External id for reference.
     * @type {string}
     * @memberof DatasetUpdate
     */
    'reference'?: string;
}
/**
 * 
 * @export
 * @interface FileCreate
 */
export interface FileCreate {
    /**
     * Primary key for objects managed by api.
     * @type {string}
     * @memberof FileCreate
     */
    'solution_id'?: string;
    /**
     * Filename provided by user.
     * @type {string}
     * @memberof FileCreate
     */
    'name': string;
    /**
     * 
     * @type {MediaType}
     * @memberof FileCreate
     */
    'type': MediaType;
    /**
     * Uploaded file.
     * @type {File}
     * @memberof FileCreate
     */
    'file': File;
}


/**
 * 
 * @export
 * @interface FilePage
 */
export interface FilePage {
    /**
     * Pagination link pointing to the current page.
     * @type {string}
     * @memberof FilePage
     */
    'self': string;
    /**
     * Pagination link pointing to the first page.
     * @type {string}
     * @memberof FilePage
     */
    'first': string;
    /**
     * Pagination link pointing to the previous page.
     * @type {string}
     * @memberof FilePage
     */
    'prev'?: string;
    /**
     * Pagination link pointing to the next page.
     * @type {string}
     * @memberof FilePage
     */
    'next'?: string;
    /**
     * Pagination link pointing to the last page.
     * @type {string}
     * @memberof FilePage
     */
    'last': string;
    /**
     * Numeric offset of the first element provided on a page representing a collection request.
     * @type {number}
     * @memberof FilePage
     */
    'offset': number;
    /**
     * Cient suggested limit to restrict the number of entries on a page. Can be truncated by server side if limit exceeds server limit.
     * @type {number}
     * @memberof FilePage
     */
    'limit': number;
    /**
     * 
     * @type {Array<any>}
     * @memberof FilePage
     */
    'items': Array<any>;
    /**
     * Total count of items.
     * @type {number}
     * @memberof FilePage
     */
    'count': number;
}
/**
 * Status of a dataset: - NEW: file uploaded and dataset not finished - METADATA_STARTED: metadata extraction started - METADATA_FINISHED: metadata extracted - METADATA_FAILED: metadata extraction failed, e.g. EXIF data is invalid - PRIVACY_STARTED: privacy filtering started - PRIVACY_FINISHED: privacy filtered - PRIVACY_FAILED: privacy filtering failed - FINISHING_STARTED: file finishing - FINISHING_FAILED: file finishing failed, e.g. azure connection failed - DATASET: file finished, but waiting for dataset finishing - FINISHED: file finished and acessible in viewer .
 * @export
 * @enum {string}
 */

export const FileState = {
    New: 'NEW',
    MetadataStarted: 'METADATA_STARTED',
    MetadataFinished: 'METADATA_FINISHED',
    MetadataFailed: 'METADATA_FAILED',
    PrivacyStarted: 'PRIVACY_STARTED',
    PrivacyFinished: 'PRIVACY_FINISHED',
    PrivacyFailed: 'PRIVACY_FAILED',
    FinishingStarted: 'FINISHING_STARTED',
    FinishingFailed: 'FINISHING_FAILED',
    Dataset: 'DATASET',
    Finished: 'FINISHED'
} as const;

export type FileState = typeof FileState[keyof typeof FileState];


/**
 * Used media types of the Media Data Platform Solution.
 * @export
 * @enum {string}
 */

export const MediaType = {
    DroneImage: 'DRONE_IMAGE',
    PointCloud: 'POINT_CLOUD',
    PhoneImage: 'PHONE_IMAGE'
} as const;

export type MediaType = typeof MediaType[keyof typeof MediaType];


/**
 * Describes an uploaded file with some common metadata.
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * Primary key for objects managed by api.
     * @type {string}
     * @memberof ModelFile
     */
    'id': string;
    /**
     * Primary key for objects managed by api.
     * @type {string}
     * @memberof ModelFile
     */
    'tenant_id': string;
    /**
     * Timestamp where object was created by user.
     * @type {string}
     * @memberof ModelFile
     */
    'created_at': string;
    /**
     * ObjectID from jwt token (claim __oid__).
     * @type {string}
     * @memberof ModelFile
     */
    'created_by': string;
    /**
     * Timestamp where object was modified by user.
     * @type {string}
     * @memberof ModelFile
     */
    'modified_at': string;
    /**
     * ObjectID from jwt token (claim __oid__).
     * @type {string}
     * @memberof ModelFile
     */
    'modified_by': string;
    /**
     * Primary key for objects managed by api.
     * @type {string}
     * @memberof ModelFile
     */
    'dataset_id': string;
    /**
     * Filename provided by user.
     * @type {string}
     * @memberof ModelFile
     */
    'name': string;
    /**
     * MIME type fo given file.
     * @type {string}
     * @memberof ModelFile
     */
    'mime': string;
    /**
     * 
     * @type {MediaType}
     * @memberof ModelFile
     */
    'type': MediaType;
    /**
     * 
     * @type {FileState}
     * @memberof ModelFile
     */
    'state': FileState;
    /**
     * Timestamp where object was modified by processing.
     * @type {string}
     * @memberof ModelFile
     */
    'state_at': string;
    /**
     * Creation date of the file, e.g. image taken timestamp.
     * @type {string}
     * @memberof ModelFile
     */
    'file_at'?: string;
    /**
     * Geometry to find files via location or spatial filter.
     * @type {string}
     * @memberof ModelFile
     */
    'geometry'?: string;
    /**
     * Source geometry defined in the file definition.
     * @type {string}
     * @memberof ModelFile
     */
    'source_geometry'?: string;
    /**
     * Definition of the 2D location based reference system.
     * @type {number}
     * @memberof ModelFile
     */
    'source_reference_system'?: number;
    /**
     * Optional error message from processing.
     * @type {string}
     * @memberof ModelFile
     */
    'error_message'?: string;
    /**
     * Size of file in bytes.
     * @type {number}
     * @memberof ModelFile
     */
    'size': number;
}


/**
 * Represents an iPEN solution in scope of tenent.
 * @export
 * @interface Solution
 */
export interface Solution {
    /**
     * Primary key for objects managed by api.
     * @type {string}
     * @memberof Solution
     */
    'id': string;
    /**
     * Immutable name of solution. Can be used as reference.
     * @type {string}
     * @memberof Solution
     */
    'name': string;
    /**
     * Display name of solution.
     * @type {string}
     * @memberof Solution
     */
    'title': string;
    /**
     * Short description of solution.
     * @type {string}
     * @memberof Solution
     */
    'description': string;
    /**
     * Logo of solution to display.
     * @type {string}
     * @memberof Solution
     */
    'logo': string;
    /**
     * APL assigend in LeanIx.
     * @type {string}
     * @memberof Solution
     */
    'lean_ix': string;
    /**
     * 
     * @type {Application}
     * @memberof Solution
     */
    'application': Application;
}
/**
 * 
 * @export
 * @interface SolutionPage
 */
export interface SolutionPage {
    /**
     * Pagination link pointing to the current page.
     * @type {string}
     * @memberof SolutionPage
     */
    'self': string;
    /**
     * Pagination link pointing to the first page.
     * @type {string}
     * @memberof SolutionPage
     */
    'first': string;
    /**
     * Pagination link pointing to the previous page.
     * @type {string}
     * @memberof SolutionPage
     */
    'prev'?: string;
    /**
     * Pagination link pointing to the next page.
     * @type {string}
     * @memberof SolutionPage
     */
    'next'?: string;
    /**
     * Pagination link pointing to the last page.
     * @type {string}
     * @memberof SolutionPage
     */
    'last': string;
    /**
     * Numeric offset of the first element provided on a page representing a collection request.
     * @type {number}
     * @memberof SolutionPage
     */
    'offset': number;
    /**
     * Cient suggested limit to restrict the number of entries on a page. Can be truncated by server side if limit exceeds server limit.
     * @type {number}
     * @memberof SolutionPage
     */
    'limit': number;
    /**
     * 
     * @type {Array<Solution>}
     * @memberof SolutionPage
     */
    'items': Array<Solution>;
    /**
     * Total count of items.
     * @type {number}
     * @memberof SolutionPage
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorItem>}
     * @memberof ValidationError
     */
    'detail': Array<ValidationErrorItem>;
    /**
     * 
     * @type {object}
     * @memberof ValidationError
     */
    'body': object | null;
}
/**
 * 
 * @export
 * @interface ValidationErrorItem
 */
export interface ValidationErrorItem {
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorItem
     */
    'type': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationErrorItem
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorItem
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorItem
     */
    'input': string;
    /**
     * 
     * @type {object}
     * @memberof ValidationErrorItem
     */
    'ctx': object;
}

/**
 * DatasetApi - axios parameter creator
 * @export
 */
export const DatasetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new dataset for current tenant.
         * @summary Create dataset
         * @param {DatasetCreate} [datasetCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataset: async (datasetCreate?: DatasetCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload drone image to given dataset. Only possible if dataset is in state NEW.
         * @summary Upload file
         * @param {string} datasetId ID of dataset to find.
         * @param {string} name Filename provided by user.
         * @param {MediaType} type 
         * @param {File} file Uploaded file.
         * @param {string} [solutionId] Primary key for objects managed by api.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile: async (datasetId: string, name: string, type: MediaType, file: File, solutionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('createFile', 'datasetId', datasetId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createFile', 'name', name)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('createFile', 'type', type)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createFile', 'file', file)
            const localVarPath = `/datasets/{dataset-id}/files`
                .replace(`{${"dataset-id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)


            if (solutionId !== undefined) { 
                localVarFormParams.append('solution_id', solutionId as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete dataset. Only possible if dataset is in state NEW.
         * @summary Delete dataset
         * @param {string} datasetId ID of dataset to find.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataset: async (datasetId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('deleteDataset', 'datasetId', datasetId)
            const localVarPath = `/datasets/{dataset-id}`
                .replace(`{${"dataset-id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete file from dataset. Only possible if dataset is in state NEW.
         * @summary Delete file
         * @param {string} datasetId ID of dataset to find.
         * @param {string} fileId ID of file to find in dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (datasetId: string, fileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('deleteFile', 'datasetId', datasetId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteFile', 'fileId', fileId)
            const localVarPath = `/datasets/{dataset-id}/files/{file-id}`
                .replace(`{${"dataset-id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"file-id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find dataset by id and return details.
         * @summary Find dataset
         * @param {string} datasetId ID of dataset to find.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDatasetById: async (datasetId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('findDatasetById', 'datasetId', datasetId)
            const localVarPath = `/datasets/{dataset-id}`
                .replace(`{${"dataset-id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all datasets for current tenant. Supported sorts are name|reference|state|created_at|modified_at. Ordered by -modified_at per default.
         * @summary Find datasets
         * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
         * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
         * @param {string} [sort] Fields to define the sort order.
         * @param {string} [solutionId] Return only datasets for given solution.
         * @param {DatasetState} [state] Return only datasets for given dataset state.
         * @param {string} [name] Return only datasets matching the given name. Supports * as wildcard and ignores case.
         * @param {string} [geom] Return only datasets that intersect with the given coordinates of a Point/Line/Area.
         * @param {string} [createdAtBefore] Return only datasets with newest created_at timestamp before this value.
         * @param {string} [createdAtAfter] Return only datasets with oldest created_at timestamp after this value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDatasets: async (offset?: number, limit?: number, sort?: string, solutionId?: string, state?: DatasetState, name?: string, geom?: string, createdAtBefore?: string, createdAtAfter?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (solutionId !== undefined) {
                localVarQueryParameter['solution_id'] = solutionId;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (geom !== undefined) {
                localVarQueryParameter['geom'] = geom;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['created_at_before'] = (createdAtBefore as any instanceof Date) ?
                    (createdAtBefore as any).toISOString() :
                    createdAtBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['created_at_after'] = (createdAtAfter as any instanceof Date) ?
                    (createdAtAfter as any).toISOString() :
                    createdAtAfter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get file from dataset.
         * @summary Get file
         * @param {string} datasetId ID of dataset to find.
         * @param {string} fileId ID of file to find in dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFileByDatasetIdAndFileId: async (datasetId: string, fileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('findFileByDatasetIdAndFileId', 'datasetId', datasetId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('findFileByDatasetIdAndFileId', 'fileId', fileId)
            const localVarPath = `/datasets/{dataset-id}/files/{file-id}`
                .replace(`{${"dataset-id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"file-id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get files of given dataset. Supported sorts are name|type|state|created_at|modified_at. Ordered by name per default.
         * @summary Get files
         * @param {string} datasetId ID of dataset to find.
         * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
         * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
         * @param {string} [sort] Fields to define the sort order.
         * @param {MediaType} [type] Return only files of given type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFilesByDatasetId: async (datasetId: string, offset?: number, limit?: number, sort?: string, type?: MediaType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('findFilesByDatasetId', 'datasetId', datasetId)
            const localVarPath = `/datasets/{dataset-id}/files`
                .replace(`{${"dataset-id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finish dataset, files are uploaded. Only possible if dataset is in state NEW.
         * @summary Finish dataset
         * @param {string} datasetId ID of dataset to finish.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishDataset: async (datasetId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('finishDataset', 'datasetId', datasetId)
            const localVarPath = `/datasets/{dataset-id}/finish`
                .replace(`{${"dataset-id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update dataset name/comment/reference.
         * @summary Update dataset
         * @param {string} datasetId ID of dataset to find.
         * @param {DatasetUpdate} [datasetUpdate] Dataset properties to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDatasetById: async (datasetId: string, datasetUpdate?: DatasetUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('updateDatasetById', 'datasetId', datasetId)
            const localVarPath = `/datasets/{dataset-id}`
                .replace(`{${"dataset-id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetApi - functional programming interface
 * @export
 */
export const DatasetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetApiAxiosParamCreator(configuration)
    return {
        /**
         * Create new dataset for current tenant.
         * @summary Create dataset
         * @param {DatasetCreate} [datasetCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataset(datasetCreate?: DatasetCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataset(datasetCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.createDataset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upload drone image to given dataset. Only possible if dataset is in state NEW.
         * @summary Upload file
         * @param {string} datasetId ID of dataset to find.
         * @param {string} name Filename provided by user.
         * @param {MediaType} type 
         * @param {File} file Uploaded file.
         * @param {string} [solutionId] Primary key for objects managed by api.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFile(datasetId: string, name: string, type: MediaType, file: File, solutionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFile(datasetId, name, type, file, solutionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.createFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete dataset. Only possible if dataset is in state NEW.
         * @summary Delete dataset
         * @param {string} datasetId ID of dataset to find.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDataset(datasetId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDataset(datasetId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.deleteDataset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete file from dataset. Only possible if dataset is in state NEW.
         * @summary Delete file
         * @param {string} datasetId ID of dataset to find.
         * @param {string} fileId ID of file to find in dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(datasetId: string, fileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(datasetId, fileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.deleteFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Find dataset by id and return details.
         * @summary Find dataset
         * @param {string} datasetId ID of dataset to find.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDatasetById(datasetId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDatasetById(datasetId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.findDatasetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Find all datasets for current tenant. Supported sorts are name|reference|state|created_at|modified_at. Ordered by -modified_at per default.
         * @summary Find datasets
         * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
         * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
         * @param {string} [sort] Fields to define the sort order.
         * @param {string} [solutionId] Return only datasets for given solution.
         * @param {DatasetState} [state] Return only datasets for given dataset state.
         * @param {string} [name] Return only datasets matching the given name. Supports * as wildcard and ignores case.
         * @param {string} [geom] Return only datasets that intersect with the given coordinates of a Point/Line/Area.
         * @param {string} [createdAtBefore] Return only datasets with newest created_at timestamp before this value.
         * @param {string} [createdAtAfter] Return only datasets with oldest created_at timestamp after this value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDatasets(offset?: number, limit?: number, sort?: string, solutionId?: string, state?: DatasetState, name?: string, geom?: string, createdAtBefore?: string, createdAtAfter?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDatasets(offset, limit, sort, solutionId, state, name, geom, createdAtBefore, createdAtAfter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.findDatasets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get file from dataset.
         * @summary Get file
         * @param {string} datasetId ID of dataset to find.
         * @param {string} fileId ID of file to find in dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFileByDatasetIdAndFileId(datasetId: string, fileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFileByDatasetIdAndFileId(datasetId, fileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.findFileByDatasetIdAndFileId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get files of given dataset. Supported sorts are name|type|state|created_at|modified_at. Ordered by name per default.
         * @summary Get files
         * @param {string} datasetId ID of dataset to find.
         * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
         * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
         * @param {string} [sort] Fields to define the sort order.
         * @param {MediaType} [type] Return only files of given type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFilesByDatasetId(datasetId: string, offset?: number, limit?: number, sort?: string, type?: MediaType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFilesByDatasetId(datasetId, offset, limit, sort, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.findFilesByDatasetId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Finish dataset, files are uploaded. Only possible if dataset is in state NEW.
         * @summary Finish dataset
         * @param {string} datasetId ID of dataset to finish.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishDataset(datasetId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishDataset(datasetId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.finishDataset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update dataset name/comment/reference.
         * @summary Update dataset
         * @param {string} datasetId ID of dataset to find.
         * @param {DatasetUpdate} [datasetUpdate] Dataset properties to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDatasetById(datasetId: string, datasetUpdate?: DatasetUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDatasetById(datasetId, datasetUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.updateDatasetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DatasetApi - factory interface
 * @export
 */
export const DatasetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetApiFp(configuration)
    return {
        /**
         * Create new dataset for current tenant.
         * @summary Create dataset
         * @param {DatasetCreate} [datasetCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataset(datasetCreate?: DatasetCreate, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.createDataset(datasetCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload drone image to given dataset. Only possible if dataset is in state NEW.
         * @summary Upload file
         * @param {string} datasetId ID of dataset to find.
         * @param {string} name Filename provided by user.
         * @param {MediaType} type 
         * @param {File} file Uploaded file.
         * @param {string} [solutionId] Primary key for objects managed by api.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile(datasetId: string, name: string, type: MediaType, file: File, solutionId?: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createFile(datasetId, name, type, file, solutionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete dataset. Only possible if dataset is in state NEW.
         * @summary Delete dataset
         * @param {string} datasetId ID of dataset to find.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataset(datasetId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteDataset(datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete file from dataset. Only possible if dataset is in state NEW.
         * @summary Delete file
         * @param {string} datasetId ID of dataset to find.
         * @param {string} fileId ID of file to find in dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(datasetId: string, fileId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFile(datasetId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Find dataset by id and return details.
         * @summary Find dataset
         * @param {string} datasetId ID of dataset to find.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDatasetById(datasetId: string, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.findDatasetById(datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all datasets for current tenant. Supported sorts are name|reference|state|created_at|modified_at. Ordered by -modified_at per default.
         * @summary Find datasets
         * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
         * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
         * @param {string} [sort] Fields to define the sort order.
         * @param {string} [solutionId] Return only datasets for given solution.
         * @param {DatasetState} [state] Return only datasets for given dataset state.
         * @param {string} [name] Return only datasets matching the given name. Supports * as wildcard and ignores case.
         * @param {string} [geom] Return only datasets that intersect with the given coordinates of a Point/Line/Area.
         * @param {string} [createdAtBefore] Return only datasets with newest created_at timestamp before this value.
         * @param {string} [createdAtAfter] Return only datasets with oldest created_at timestamp after this value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDatasets(offset?: number, limit?: number, sort?: string, solutionId?: string, state?: DatasetState, name?: string, geom?: string, createdAtBefore?: string, createdAtAfter?: string, options?: RawAxiosRequestConfig): AxiosPromise<DatasetPage> {
            return localVarFp.findDatasets(offset, limit, sort, solutionId, state, name, geom, createdAtBefore, createdAtAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * Get file from dataset.
         * @summary Get file
         * @param {string} datasetId ID of dataset to find.
         * @param {string} fileId ID of file to find in dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFileByDatasetIdAndFileId(datasetId: string, fileId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.findFileByDatasetIdAndFileId(datasetId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get files of given dataset. Supported sorts are name|type|state|created_at|modified_at. Ordered by name per default.
         * @summary Get files
         * @param {string} datasetId ID of dataset to find.
         * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
         * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
         * @param {string} [sort] Fields to define the sort order.
         * @param {MediaType} [type] Return only files of given type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFilesByDatasetId(datasetId: string, offset?: number, limit?: number, sort?: string, type?: MediaType, options?: RawAxiosRequestConfig): AxiosPromise<FilePage> {
            return localVarFp.findFilesByDatasetId(datasetId, offset, limit, sort, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Finish dataset, files are uploaded. Only possible if dataset is in state NEW.
         * @summary Finish dataset
         * @param {string} datasetId ID of dataset to finish.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishDataset(datasetId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.finishDataset(datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update dataset name/comment/reference.
         * @summary Update dataset
         * @param {string} datasetId ID of dataset to find.
         * @param {DatasetUpdate} [datasetUpdate] Dataset properties to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDatasetById(datasetId: string, datasetUpdate?: DatasetUpdate, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.updateDatasetById(datasetId, datasetUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetApi - object-oriented interface
 * @export
 * @class DatasetApi
 * @extends {BaseAPI}
 */
export class DatasetApi extends BaseAPI {
    /**
     * Create new dataset for current tenant.
     * @summary Create dataset
     * @param {DatasetCreate} [datasetCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public createDataset(datasetCreate?: DatasetCreate, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).createDataset(datasetCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload drone image to given dataset. Only possible if dataset is in state NEW.
     * @summary Upload file
     * @param {string} datasetId ID of dataset to find.
     * @param {string} name Filename provided by user.
     * @param {MediaType} type 
     * @param {File} file Uploaded file.
     * @param {string} [solutionId] Primary key for objects managed by api.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public createFile(datasetId: string, name: string, type: MediaType, file: File, solutionId?: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).createFile(datasetId, name, type, file, solutionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete dataset. Only possible if dataset is in state NEW.
     * @summary Delete dataset
     * @param {string} datasetId ID of dataset to find.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public deleteDataset(datasetId: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).deleteDataset(datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete file from dataset. Only possible if dataset is in state NEW.
     * @summary Delete file
     * @param {string} datasetId ID of dataset to find.
     * @param {string} fileId ID of file to find in dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public deleteFile(datasetId: string, fileId: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).deleteFile(datasetId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find dataset by id and return details.
     * @summary Find dataset
     * @param {string} datasetId ID of dataset to find.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public findDatasetById(datasetId: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).findDatasetById(datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all datasets for current tenant. Supported sorts are name|reference|state|created_at|modified_at. Ordered by -modified_at per default.
     * @summary Find datasets
     * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
     * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
     * @param {string} [sort] Fields to define the sort order.
     * @param {string} [solutionId] Return only datasets for given solution.
     * @param {DatasetState} [state] Return only datasets for given dataset state.
     * @param {string} [name] Return only datasets matching the given name. Supports * as wildcard and ignores case.
     * @param {string} [geom] Return only datasets that intersect with the given coordinates of a Point/Line/Area.
     * @param {string} [createdAtBefore] Return only datasets with newest created_at timestamp before this value.
     * @param {string} [createdAtAfter] Return only datasets with oldest created_at timestamp after this value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public findDatasets(offset?: number, limit?: number, sort?: string, solutionId?: string, state?: DatasetState, name?: string, geom?: string, createdAtBefore?: string, createdAtAfter?: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).findDatasets(offset, limit, sort, solutionId, state, name, geom, createdAtBefore, createdAtAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get file from dataset.
     * @summary Get file
     * @param {string} datasetId ID of dataset to find.
     * @param {string} fileId ID of file to find in dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public findFileByDatasetIdAndFileId(datasetId: string, fileId: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).findFileByDatasetIdAndFileId(datasetId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get files of given dataset. Supported sorts are name|type|state|created_at|modified_at. Ordered by name per default.
     * @summary Get files
     * @param {string} datasetId ID of dataset to find.
     * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
     * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
     * @param {string} [sort] Fields to define the sort order.
     * @param {MediaType} [type] Return only files of given type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public findFilesByDatasetId(datasetId: string, offset?: number, limit?: number, sort?: string, type?: MediaType, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).findFilesByDatasetId(datasetId, offset, limit, sort, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Finish dataset, files are uploaded. Only possible if dataset is in state NEW.
     * @summary Finish dataset
     * @param {string} datasetId ID of dataset to finish.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public finishDataset(datasetId: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).finishDataset(datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update dataset name/comment/reference.
     * @summary Update dataset
     * @param {string} datasetId ID of dataset to find.
     * @param {DatasetUpdate} [datasetUpdate] Dataset properties to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public updateDatasetById(datasetId: string, datasetUpdate?: DatasetUpdate, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).updateDatasetById(datasetId, datasetUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MasterdataApi - axios parameter creator
 * @export
 */
export const MasterdataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Find solutions for current tenant. Supported sorts are name|title|lean_ix. Ordered by title per default.
         * @summary Find solutions
         * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
         * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
         * @param {string} [sort] Fields to define the sort order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSolutions: async (offset?: number, limit?: number, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/solutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DsoToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication iPEN_Demo_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Demo_DSO", ["api://eb6e693a-b625-4574-b519-47f60e48fe64/.default"], configuration)

            // authentication iPEN_Test_DSO required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "iPEN_Test_DSO", ["api://b87975ca-e467-4ff8-a6f5-de640ff2311f/.default"], configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterdataApi - functional programming interface
 * @export
 */
export const MasterdataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterdataApiAxiosParamCreator(configuration)
    return {
        /**
         * Find solutions for current tenant. Supported sorts are name|title|lean_ix. Ordered by title per default.
         * @summary Find solutions
         * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
         * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
         * @param {string} [sort] Fields to define the sort order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSolutions(offset?: number, limit?: number, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SolutionPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSolutions(offset, limit, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MasterdataApi.findSolutions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MasterdataApi - factory interface
 * @export
 */
export const MasterdataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterdataApiFp(configuration)
    return {
        /**
         * Find solutions for current tenant. Supported sorts are name|title|lean_ix. Ordered by title per default.
         * @summary Find solutions
         * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
         * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
         * @param {string} [sort] Fields to define the sort order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSolutions(offset?: number, limit?: number, sort?: string, options?: RawAxiosRequestConfig): AxiosPromise<SolutionPage> {
            return localVarFp.findSolutions(offset, limit, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MasterdataApi - object-oriented interface
 * @export
 * @class MasterdataApi
 * @extends {BaseAPI}
 */
export class MasterdataApi extends BaseAPI {
    /**
     * Find solutions for current tenant. Supported sorts are name|title|lean_ix. Ordered by title per default.
     * @summary Find solutions
     * @param {number} [offset] Numeric offset of the first element provided on a page representing a collection request.
     * @param {number} [limit] Cient suggested limit to restrict the number of entries on a page.
     * @param {string} [sort] Fields to define the sort order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterdataApi
     */
    public findSolutions(offset?: number, limit?: number, sort?: string, options?: RawAxiosRequestConfig) {
        return MasterdataApiFp(this.configuration).findSolutions(offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



